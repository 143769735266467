import React from "react"
import Grid from "../Grid/Grid"
import styled from "styled-components"
import {
  MdLocationOn,
  MdEmail,
  MdLocalPhone,
  MdAccessTime,
} from "react-icons/md"

const Section = styled.section``

const Title = styled.h1`
  margin-top: 0;
  font-size: var(--h1);

  @media (min-width: 768px) {
    grid-column: 1 / 2;
  }
`

const SubContent = styled.div`
  @media (min-width: 768px) {
    grid-column: 2 / 4;
  }

  p {
    margin-bottom: 2.125rem;
  }
`

const SubTitle = styled.h2`
  @media (min-width: 768px) {
    margin-top: 0;
  }
`

const Form = styled.form`
  input {
    height: 50px;
    margin-bottom: 1.25rem;

    @media (min-width: 1200px) {
      margin-bottom: 1.875rem;
    }
  }
  input,
  textarea {
    color: rgb(0 0 0 / 70%);
    border: none;
    border-bottom: 3px solid var(--inActive);
    width: 100%;
    font-size: 1rem;
    font-weight: 900;
    font-family: "Heebo", sans-serif;
    padding: 15px;
    transition: border-bottom-color 0.3s;

    &:focus {
      border-bottom-color: var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  textarea {
    margin-bottom: 2.125rem;
  }
`

const ContactList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    font-size: var(--menuItem);
    font-weight: 500;
    margin-bottom: 0.3em;
  }

  svg {
    vertical-align: middle;
    color: var(--primary);
    margin-right: 0.7em;
    height: 1.2em;
    width: 1.2em;
  }
`

const Contact = () => {
  return (
    <Section className="section-padding">
      <Grid>
        <Title>Contact.</Title>
        <SubContent>
          <SubTitle>Interested? Get in touch</SubTitle>
          <p>
            If you’re not sure how to start or what you need, simply call or
            fill in the contact form and we will help you decide what will work
            best for you.
          </p>
          <ContactList>
            <li>
              <MdLocationOn />
              224, Bella Vista, Chicalim Goa, 403711
            </li>
            <li>
              <a href="mailto:adcity.advertising@gmail.com">
                <MdEmail />
                adcity.advertising@gmail.com
              </a>
            </li>
            <li>
              <a href="tel:+91 9922568787">
                <MdLocalPhone />
                9922568787, 9822130547
              </a>
            </li>
            <li>
              <MdAccessTime />
              9:00 AM- 6:00 PM
            </li>
          </ContactList>

          <Form name="contact" netlify>
            <input placeholder="Your name" type="text" name="name" required />
            <input
              placeholder="Your email"
              type="email"
              name="email"
              required
            />
            <textarea
              placeholder="Your message"
              name="message"
              rows="5"
              required
            ></textarea>
            <button className="btn" type="submit">
              Send Message
            </button>
          </Form>
        </SubContent>
      </Grid>
    </Section>
  )
}

export default Contact
